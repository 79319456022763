import { Col, Row } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import amplify from '../assets/conf/amplify.json'
import ContactForm from '../components/ContactForm'
import Layout from '../components/Layout'
import QuickLinks from '../components/QuickLinks'

const ContactPage = () => (
  <Layout selectedMenuKey="contact">
    <Row className="container my3" type="flex" justify="center">
      <Col md={24}>
        <Row>
          <QuickLinks />
        </Row>
      </Col>
      <Col xs={24} md={8} className="m3">
        <Row className="bold">
          <Col md={24} xs={24} className="h3">
            <FormattedMessage id="comp.layout.contact" />
          </Col>
        </Row>
        <Row className="bold py1">
          {/* <Col md={6}>
            <FormattedMessage id="comp.layout.contact.call-us" />
          </Col>
          <Col md={12}>
            234-678-8900
          </Col> */}
        </Row>
        <Row className="bold">
          <Col md={6} className="py1 mr2">
            <FormattedMessage id="comp.layout.contact.address" />
          </Col>
          <Col md={12} className="py1">
            <p className="p0 m0">45041 Ocean Park</p>
            <p className="p0 m0">Surrey, BC V4A 9L1, Canada</p>
          </Col>
          <Col md={20} xs={20} className="center py2">
            <iframe
              title="Raffskie"
              src={`${amplify.raffskieMapLink}`}
              frameBorder="0"
              style={{ border: '0', width: '100%', height: '300px' }}
              allowFullScreen=""
              aria-hidden="false"
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={10} className="m3 center">
        <Row>
          <Col md={24} xs={24} className="h3 bold">
            Send us an inquiry
          </Col>
          <Col md={24} xs={24} className="py3">
            <ContactForm />
          </Col>
        </Row>
      </Col>
    </Row>
  </Layout>
)

export default ContactPage
