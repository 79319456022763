import Cache from '@aws-amplify/cache'
import {
  Button,
  Col,
  Row,
} from 'antd'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const QuickLinks = ({ industry }) => {
  const size = 'large'
  const inActive = { background: '#4e4e4e', borderColor: '#4e4e4e' }

  return (
    <Col xs={24} md={14}>
      <Row align="middle" className="center">
        <Col className="mr1">
          <Button
            type="primary"
            shape="round"
            size={size}
            style={inActive}
          >
            <Link to="/" style={{ color: 'white' }}>
              <FormattedMessage id="common.home" />
            </Link>
          </Button>
        </Col>
        <Col className="mr1">
          <Button type="primary" shape="round" size={size} style={industry === 'health' ? {} : inActive}>
            <Link
              to="/health"
              state={{ industry: 'health' }}
              onClick={() => Cache.setItem('rsk-web.industry', 'health')}
            >
              <FormattedMessage id="industry.health" />
            </Link>
          </Button>
        </Col>
        <Col>
          <Button type="primary" shape="round" size={size} style={industry === 'leisure' ? {} : inActive}>
            <Link
              to="/leisure"
              state={{ industry: 'leisure' }}
              onClick={() => Cache.setItem('rsk-web.industry', 'leisure')}
            >
              <FormattedMessage id="industry.leisure" />
            </Link>
          </Button>
        </Col>
      </Row>
    </Col>
  )
}

QuickLinks.propTypes = {
  industry: PropTypes.string,
}

QuickLinks.defaultProps = {
  industry: '',
}

export default QuickLinks
